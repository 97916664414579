// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\Users\\clewi\\git\\rrd\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("C:\\Users\\clewi\\git\\rrd\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-dodgeball-js": () => import("C:\\Users\\clewi\\git\\rrd\\src\\pages\\dodgeball.js" /* webpackChunkName: "component---src-pages-dodgeball-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\clewi\\git\\rrd\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-juniors-js": () => import("C:\\Users\\clewi\\git\\rrd\\src\\pages\\juniors.js" /* webpackChunkName: "component---src-pages-juniors-js" */),
  "component---src-pages-members-js": () => import("C:\\Users\\clewi\\git\\rrd\\src\\pages\\members.js" /* webpackChunkName: "component---src-pages-members-js" */),
  "component---src-pages-newskaters-js": () => import("C:\\Users\\clewi\\git\\rrd\\src\\pages\\newskaters.js" /* webpackChunkName: "component---src-pages-newskaters-js" */),
  "component---src-pages-payments-js": () => import("C:\\Users\\clewi\\git\\rrd\\src\\pages\\payments.js" /* webpackChunkName: "component---src-pages-payments-js" */),
  "component---src-pages-sponsors-js": () => import("C:\\Users\\clewi\\git\\rrd\\src\\pages\\sponsors.js" /* webpackChunkName: "component---src-pages-sponsors-js" */),
  "component---src-pages-testpage-js": () => import("C:\\Users\\clewi\\git\\rrd\\src\\pages\\testpage.js" /* webpackChunkName: "component---src-pages-testpage-js" */)
}

